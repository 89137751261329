<template>
  <div class="max-w-md mx-auto p-4 bg-white mt-8">
    <label for="" class="text-2xl">Appointment</label>
    <div class="text-left p-2 relative">
      <label for="">Find Patient</label>
      <InputNew
        type="number"
        v-model="phone"
        @input="onChange"
        class="w-full py-2 p-2 focus:outline-none"
        placeholder="Phone Number..."
      />
      <div class="-mt-8 right-0 mr-8 absolute">
        <!-- <span @click="findDoctor(getUser)" class="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </span> -->
      </div>
    </div>
    <!-- {{ user_id }}   -->
    <div class="p-2" v-if="user">
      <InputNew :value="user.name" class="w-full py-2 p-2 focus:outline-none" />
    </div>
    <div v-if="!user" class="flex flex-col gap-4 p-2">
      <input-new
        type="text"
        v-model="name"
        class="w-full py-2 p-2 border focus:outline-none"
        placeholder="Patient Name"
      />
      <input-new
        type="number"
        class="w-full py-2 p-2 border focus:outline-none"
        :value="getUser"
        placeholder="Phone Number"
      />
    </div>

    <div class="text-left p-1">
      <label for="">Appointment Type</label>
      <div class="bg-gray-200 p-2 flex gap-4 md:mx-auto rounded">
        <label v-for="type in appointmentTypes.support_types" :key="type.id">
          <div
            :class="{ 'bg-white text-brand': supportType == type.id }"
            class="cursor-pointer bg-white overflow-hidden relative text-left shadow p-2 rounded hover:shadow-lg"
          >
            <input
              type="radio"
              v-model="supportType"
              :value="type.id"
              class="absolute -left-full"
              @click="payAble(type.name)"
            />
            <span class="p-2 hover:text-brand">{{ type.name }}</span>
          </div>
        </label>
      </div>
    </div>
    <div class="text-left p-1">
      <label for="">Question</label>
      <div class="grid grid-cols-5 gap-2">
        <div class="col-span-full">
          <div
            class="grid grid-cols-5 mb-2"
            v-for="(question, index) in questions"
            :key="index"
          >
            <Textarea
              class="col-span-4 py-2 p-2 border focus:outline-none"
              v-model="questions[index]"
              placeholder="Please share your problem with us to make the counselling session best effective. Your all informations will be kept confidential."
            />
            <button class="col-span-1 text-red-500" @click="remove(index)">
              &#9587;
            </button>
          </div>
        </div>
        <Btn type="button" @click="addQuestions()"> + Add</Btn>
      </div>
    </div>
    <div class="text-left">
      <label for="">Payable</label>
      <input
        type="number"
        class="w-full py-2 rounded border focus:outline-none p-2"
        v-model="payable"
      />
    </div>
    <!-- <div class="text-left" v-if="HasPayable">
      <label for="">Payable</label>
      <div class="flex gap-4 p-2">
        <InputRadio title="Yes" value="1" v-model="haspayable" />
        <InputRadio title="No" value="0" v-model="haspayable" />
      </div>
      <div v-if="haspayable == 1">
        <input
          type="number"
          class="w-full py-2 rounded border focus:outline-none p-2"
          v-model="payable"
        />
      </div>
    </div> -->

    <div class="text-left p-1">
      <label for="">Mentor</label>
      <Select
        type="text"
        class="w-full border py-3 bg-white outline-none p-2"
        v-model="request_mentor_id"
      >
        <option value="">Select Mentor</option>
        <option
          v-for="(mentor, index) in mentors[supportType]"
          :key="index"
          :value="mentor.id"
        >
          {{ mentor.name }}
        </option>
      </Select>
    </div>
    <div class="mt-2">
      <Btn class="" type="submit" @click="submitForm()">Submit</Btn>
    </div>

    <div class="flex justify-between items-center mt-4">
      <div
        @click="$router.go(-1)"
        class="text-brand rounded-md py-1.5 px-2 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "../../../../components/select.vue";
import InputNew from "../../../../components/input-new.vue";
import InputRadio from "../../../../components/input-radio.vue";
import InputText from "../../../../components/input-text.vue";
import Textarea from "../../../../components/input-text-area.vue";
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import Btn from "../../../../components/btn.vue";
import axios from "../../../../store/axios";
export default {
  components: {
    Select,
    InputNew,
    Textarea,
    Btn,
    InputText,
    DatePicker,
    Calendar,
    InputRadio,
  },
  data() {
    return {
      user_id: "",

      schedule_id: this.$route.params.scheduleId,
      serial: "",
      question: "",
      payable: "",
      type: "",
      chamberId: "",
      supportType: "",
      phone: "",
      name: "",
      questions: [""],
      request_mentor_id: "",
    };
  },

  created() {
    // this.isNullReg = false;
    this.$store.dispatch("adminAppointment/getAppointmentTypes");
  },
  // watch: {
  //     user( user ){
  //         if(!user){
  //             this.isNullReg = true
  //         }
  //     }
  //     },

  computed: {
    appointmentTypes() {
      return this.$store.state.adminAppointment.types;
    },
    getUser() {
      return this.phone;
    },
    findUser() {
      return this.$store.state.adminAppointment.findUser || "";
    },
    user() {
      const user = this.findUser.user || "";
      if (user) {
        this.user_id = user.id;
        return user;
      }
    },
    mentors() {
      return this.appointmentTypes.mentor || "";
    },
  },
  methods: {
    async submitForm() {
      // console.log(this.name, this.phone);
      await axios()
        .post("/api/appointments", {
          user_id: this.user_id,
          phone: this.phone,
          name: this.name,
          schedule_id: this.schedule_id,
          type: this.supportType,
          serial: this.serial,
          questions: this.questions,
          requested_mentor_id: this.request_mentor_id,
          payable: this.payable || 0,
        })
        .then(() => {
          this.$toast.success(`Appointment Successfuly Created !!!`, {
            position: "top-right",
            duration: 3000,
          });
          this.$router.push(
            `/admin/schedules/${this.schedule_id}/appointments`
          );
        });
    },
    onChange() {
      if (this.getUser.length > 10) {
        this.$store.dispatch("adminAppointment/findUser", {
          phone: this.getUser,
        });
      }
    },
    addQuestions() {
      console.log("asche", this.questions);
      this.questions.push("");
    },

    remove(index) {
      this.questions.splice(index, 1);
    },
    // payAble(element) {
    //   // alert(element);
    //   if (element === "Psychological Support") {
    //     this.HasPayable = true;
    //   }
    //   if (element === "Career Counselling") {
    //     console.log((this.HasPayable = false));
    //   }
    // },
  },
};
</script>
